export default {
    getTypicalContractList: (state) => {
        return state.typicalContractList;
    },
    getTypicalContractListCount: (state) => {
        return state.typicalContractListCount;
    },
    getTypicalContractMainFilter: (state) => {
        return state.typicalContractFilter.main_filter;
    },
    getTypicalContractFilterFields: (state) => {
        return state.typicalContractFilter.fields;
    },
    getTypicalContractModalOpen: (state) => {
        return state.typicalContractModalOpen;
    },
    getTypicalContractViewOpen: (state) => {
        return state.typicalContractViewOpen;
    },
    getTypicalContractIdAndTypicalContractIndex: (state) => {
        return {
            typical_contract_id: state.typical_contract_id,
            typical_contract_index: state.typical_contract_index
        };
    },
    getStateValue: (state) => (key) => {
        let result = undefined;

        if (state.hasOwnProperty(key)) {
            result = state[key];
        }

        return result;
    },
}
