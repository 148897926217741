import router from "../../../router";
import axios from "axios";

export default {
    loadTypicalContractList({ commit, dispatch }, payload)
    {
        return new Promise((resolve, reject) => {
            let filter_fields = this.getters['typicalContracts/getTypicalContractFilterFields'];
            let main_filter = this.getters['typicalContracts/getTypicalContractMainFilter'];

            if (!payload['preloader_disable'] || payload['preloader_disable'] == false) {
                dispatch('pagePreloader/setPagePreloaderIsActive', true, {root: true});

                commit('setTypicalContractList', []);
            }

            if (!main_filter && router.currentRoute.query.main_filter) {
                main_filter = router.currentRoute.query.main_filter;

                commit('setTypicalContractFilterFieldsMutation', {main_filter: router.currentRoute.query.main_filter});
            }

            if (!filter_fields.typicalContractNumber && router.currentRoute.query.typical_contract_number) {
                filter_fields.typicalContractNumber = router.currentRoute.query.typical_contract_number;

                commit('setTypicalContractFilterFieldsMutation', {field_name: 'typicalContractNumber', field_value: router.currentRoute.query.typical_contract_number})
            }

            if (!filter_fields.typicalContractTitle && router.currentRoute.query.typical_contract_title) {
                filter_fields.typicalContractTitle = router.currentRoute.query.typical_contract_title;

                commit('setTypicalContractFilterFieldsMutation', {field_name: 'typicalContractTitle', field_value: router.currentRoute.query.typical_contract_title})
            }

            payload['filter_fields'] = filter_fields;
            payload['main_filter'] = main_filter;

            axios.post('/api/typical-contract/list', payload).then((response) => {
                const result = response.data.data;

                if (response.data.data.code != 'ok')
                {
                    resolve({
                        error: true,
                        error_msg: 'Server response code not ok!',
                    });

                    return;
                }

                if (!response.data.data.result) {
                    resolve({
                        error: true,
                        error_msg: 'Result is empty!',
                    });

                    return;
                }

                dispatch('pagePreloader/setPagePreloaderIsActive', false, {root: true});

                commit('setTypicalContractList', response.data.data.result.typical_contracts);
                commit('setTypicalContractListCount', response.data.data.result.typical_contracts_count);

                resolve(true);
            }).catch((error) => {
                reject(false);
            });
        });
    },
    setTypicalContractFilterFields({ commit }, payload)
    {
        commit('setTypicalContractFilterFieldsMutation', payload);
    },
    deleteTypicalContracts({ commit }, payload)
    {
        return new Promise((resolve, reject) => {
            axios.post('/api/typical-contract/delete', {
                ids: payload
            }).then(response => {
                const result = response.data.data;

                if (response.data.data.result.success !== true) {
                    resolve(false);
                }

                commit('deleteTypicalContractsMutation', payload);

                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    },
    setTypicalContractModalOpen({commit}, payload)
    {
        commit('setTypicalContractModalOpenMutation', payload);
    },
    setTypicalContractIdAndTypicalContractIndexToModalForm({commit}, payload)
    {
        commit('setTypicalContractIdAndTypicalContractIndexToModalFormMutation', payload);
    },
    addTypicalContractToTypicalContractList({commit}, payload)
    {
        commit('addTypicalContractToTypicalContractList', payload);
    },
    updateTypicalContract({ commit }, payload)
    {
        commit('updateTypicalContract', payload);
    },
    setStateValue({commit}, payload) {
        commit('setStateValue', payload);
    },
    clearContractViewModal({commit}, payload) {
        commit('clearContractViewModal', payload);
    },
    fetchContractData({ commit }, payload)
    {
        return axios.post(`/api/typical-contract/get`, payload).then(response => {
            if (response.data.data.code !== 'ok') {
                return {
                    status: false,
                    message: 'Something went wrong',
                };
            }

            if (
                response.data.data.result.typical_contract
                && response.data.data.result.typical_contract.description
            ) {
                commit('setStateValue', {key: 'contractDescription', value: response.data.data.result.typical_contract.description})
            }
        }).catch(error => {
            if (error.response && error.response.status === 422) {
                return {
                    status: false,
                    errors: error.response.data.errors,
                };
            }

            return {
                status: false,
                message: error.message || 'Something went wrong',
            };
        });
    },
    confirmContract({ commit }, payload)
    {
        return axios.post(`/api/typical-contract/confirm`, payload).then(response => {
            if (response.data.data.code !== 'ok') {
                return {
                    status: false,
                    message: 'Something went wrong',
                };
            }

            if (
                response.data.data.result.typical_contract
                && response.data.data.result.typical_contract.confirmed_at
            ) {
                commit('setStateValue', {key: 'contractConfirmedAt', value: response.data.data.result.typical_contract.confirmed_at})
                commit('confirmContract', response.data.data.result.typical_contract);
            }
        }).catch(error => {
            if (error.response && error.response.status === 422) {
                return {
                    status: false,
                    errors: error.response.data.errors,
                };
            }

            return {
                status: false,
                message: error.message || 'Something went wrong',
            };
        });
    },
}
