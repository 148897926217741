export default {
    setTypicalContractFilterFieldsMutation(state, payload)
    {
        state.typicalContractFilter.fields.typicalContractNumber = payload.typical_contract_number;
        state.typicalContractFilter.fields.typicalContractTitle = payload.typical_contract_title;
        state.typicalContractFilter.main_filter = payload.main_filter;
    },
    setTypicalContractList(state, payload)
    {
        state.typicalContractList = payload;
    },
    setTypicalContractListCount(state, payload) {
        state.typicalContractListCount = payload;
    },
    deleteTypicalContractsMutation(state, payload)
    {
        if (payload.length) {
            let index = null;

            payload.forEach(function(item, i, payload) {
                //document.getElementById(`typical-contract-id-${item}`).checked = false;

                index = state.typicalContractList.findIndex(typical_contract => typical_contract.id == item);

                if (index !== -1) {
                    state.typicalContractList.splice(index, 1);
                }
            });
        }
    },
    setTypicalContractModalOpenMutation(state, payload)
    {
        state.typicalContractModalOpen = payload;
    },
    setTypicalContractIdAndTypicalContractIndexToModalFormMutation(state, payload)
    {
        state.typical_contract_id = payload.typical_contract_id;
        state.typical_contract_index = payload.typical_contract_index;
    },
    addTypicalContractToTypicalContractList(state, payload)
    {
        state.typicalContractList.push(payload);
    },
    updateTypicalContract(state, payload)
    {
        state.typicalContractList[payload.typical_contract_index].title = payload.title;
    },
    setStateValue(state, payload)
    {
        if (state.hasOwnProperty(payload.key)) {
            state[payload.key] = payload.value;
        }
    },
    clearContractViewModal(state, payload)
    {
        state.contractId = null;
        state.contractDescription = null;
        state.contractConfirmedAt = null;
    },
    confirmContract(state, payload)
    {
        if (payload) {
            let index = null;
            let idList = Array.isArray(payload.id) ? payload.id : [payload.id];

            idList.forEach(function(item, i, id) {
                index = state.typicalContractList.findIndex(contract => contract.id == item);

                if (index !== -1) {
                    state.typicalContractList[index].confirmed_at = payload.confirmed_at;
                }
            });
        }
    },
}
