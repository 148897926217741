import axios from "axios";
import {mapGetters} from "vuex";
import Autocomplete from "@/components/helpers/autocomplete/Autocomplete.vue";

export default {
    name: 'ModalDocumentForm',
    components: {
        Autocomplete,
    },
    data () {
        return {
            loading_add_document: false,

            document_id: null,
            document_index: null,
            document_link: null,
            document_status: null,

            showLeaderStampPlace: false,

            title: null,
            document_number: null,
            document_generate_type: null,
            company_id: null,
            stamp_page_number: 'all',
            company_role: 'member',
            leader_stamp_place: 'left',
            count_client_signatures: null,
            count_customer_signatures: null,
            document: null,
            podpis_documents_url: process.env.VUE_APP_PODPIS_DOCUMENTS_URL,

            companyList: [],
            signatories: [],

            showDocumentHistory: false,
            documentHistory: [],

            signatory: {
                id: null,
                index: null,
                user_name: null,
                surname: null,
                name: null,
                patronymic: null,
                email: null,
                phone: null,
                stamp_place: null,
                is_stamped: null,
            },

            getUserPositions: 'api/document-signatory/get-user-positions',
            positionUser: {
                id: null,
                phone: null,
            },

            signatoried_count: 0,
            executor_stamp_date_time: null,
        }
    },
    props: {
        show: {
            default: false
        }
    },
    computed: {
        ...mapGetters({
            getDocumentIdAndDocumentIndex: 'documents/getDocumentIdAndDocumentIndex',
        }),
    },
    methods: {
        closeForm()
        {
            const app = this;

            app.$store.dispatch('modalDownloadDocumentForm/toggleFormState', false);

            app.$store.dispatch('documents/setDocumentIdAndDocumentIndexToModalForm',
                {document_id: null, document_index: null, document_link: null});

            app.document_id = null;
            app.document_index = null;
            app.document_link = null;

            app.title = null;
            app.document_number = null;
            app.document_generate_type = null;
            app.company_id = null;
            app.stamp_page_number = 'all';
            app.company_role = 'member';
            app.leader_stamp_place = 'left';
            app.document = null;
            app.document_status = null;
            app.loading_add_document = false;
            app.signatoried_count = 0;
            app.executor_stamp_date_time = null;
            app.showDocumentHistory = false;

            app.showLeaderStampPlace = false;

            if (app.$route.query.modal == 'edit') {
                app.$router.push({name: 'documents'});
            }
        },
        previewFile(event) {
            const app = this;

            app.document = event.target.files;
        },
        saveDocument(hideModal)
        {
            const app = this;

            app.loading_add_document = true;

            let url = '/api/document/store-document';

            if (app.document_id > 0) {
                url = '/api/document/update-document';
            }

            if (app.document_id) {
                axios.post(url, {
                    id: app.document_id,
                    title: app.title,
                    document_number: app.document_number,
                    leader: app.leader,
                    leader_stamp_place: app.leader_stamp_place,
                    stamp_id: app.stamp_id,
                    stamp_page_number: app.stamp_page_number,
                    document_phone: app.document_phone,
                    client_id: app.client_id,
                    company_id: app.company_id,
                    count_client_signatures: app.count_client_signatures,
                    count_customer_signatures: app.count_customer_signatures,
                    company_role: app.company_role,
                    document_generate_type: app.document_generate_type,
                }).then(response => {
                    const result = response.data.data;

                    if (result.code === 'ok' && result.result.success === true) {
                        if (result.result.document) {
                            app.message = '';

                            if (result.result.action == 'store') {
                                //app.$store.dispatch('documents/addDocumentToDocumentList', result.result.document);

                                app.document_id = result.result.document.id;
                            }

                            if (result.result.action == 'update') {
                                if (app.document_index === null) {
                                    app.document_index = app.getDocumentList.length - 1;
                                }

                                result.result.document.document_index = app.document_index;
                                app.$store.dispatch('documents/updateDocument', result.result.document);
                            }
                        }

                        app.closeForm(false);

                        app.$toaster.success('Данные успешно сохранены');
                    } else {
                        if (result.result.message) {
                            app.$toaster.error(result.result.message);
                        } else {
                            app.$toaster.error('Ошибка создании клиента!');
                        }
                    }
                }).catch(error => {
                    console.log(error);
                    app.$toaster.error('Ошибка сохранения');
                }).finally(() => {
                    setTimeout(() => {
                        app.loading_add_document = false;
                    }, 1000);
                });
            } else {
                const data = new FormData(document.getElementById('uploadForm'));

                if (app.document == null) {
                    alert('Документ не выбран!');

                    app.loading_add_document = false;

                    return;
                }

                if (app.title == null) {
                    alert('Название не указано!');

                    app.loading_add_document = false;

                    return;
                }

                if (app.company_id == null) {
                    alert('Компания не указана!');

                    app.loading_add_document = false;

                    return;
                }

                data.append('document_file', app.document[0]);

                if (app.title) {
                    data.append('title', app.title);
                }

                if (app.leader) {
                    data.append('leader', app.leader);
                }

                if (app.stamp_id) {
                    data.append('stamp_id', app.stamp_id);
                }

                if (app.stamp_page_number) {
                    data.append('stamp_page_number', app.stamp_page_number);
                }

                if (app.document_phone) {
                    data.append('document_phone', app.document_phone);
                }

                if (app.client_id) {
                    data.append('client_id', app.client_id);
                }

                if (app.company_id) {
                    data.append('company_id', app.company_id);
                }

                if (app.count_client_signatures) {
                    data.append('count_client_signatures', app.count_client_signatures);
                }

                if (app.count_customer_signatures) {
                    data.append('count_customer_signatures', app.count_customer_signatures);
                }

                if (app.leader_stamp_place) {
                    data.append('leader_stamp_place', app.leader_stamp_place);
                }

                if (app.company_role) {
                    data.append('company_role', app.company_role);
                }

                if (app.document_generate_type) {
                    data.append('document_generate_type', app.document_generate_type);
                }

                if (app.document_number) {
                    data.append('document_number', app.document_number);
                }

                data.append('version', '1.0');

                axios.post('/api/document/store-document-client', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    const result = response.data.data;

                    if (result.code === 'ok' && result.result.success === true) {
                        if (result.result.document) {
                            app.$store.dispatch('documents/addDocumentToDocumentList', result.result.document);

                            if (hideModal) {
                                app.closeForm(false);
                            } else {
                                setTimeout(() => {
                                    window.location.replace('/documents?modal=edit&id=' + result.result.document.id);
                                }, 500);
                            }

                            app.$toaster.success('Данные успешно сохранены');
                        }
                    } else {
                        if (result.result.message) {
                            app.$toaster.error(result.result.message);
                        } else {
                            app.$toaster.error('Ошибка создании клиента!');
                        }
                    }
                }).catch(error => {
                    console.log(error.response)
                })
            }
        },
        showSignatoryModal() {
            const app = this;

            app.$refs['modal-signatory'].show();
        },
        hideSignatoryModal() {
            const app = this;

            app.signatory.id = null;
            app.signatory.index = null;
            app.signatory.user_name = null;
            app.signatory.surname = null;
            app.signatory.name = null;
            app.signatory.patronymic = null;
            app.signatory.email = null;
            app.signatory.phone = null;
            app.signatory.stamp_place = null;
            app.signatory.is_stamped = null;

            app.positionUser.id = null;
            app.positionUser.phone = null;

            app.$refs['modal-signatory'].hide();
        },
        saveSignatory() {
            const app = this;

            if (app.signatory.phone == null) {
                alert('Номер телефона не указан!');

                return;
            }

            let url = '/api/document-signatory/add';

            if (app.signatory.id > 0) {
                url = '/api/document-signatory/update';
            }

            axios.post(url, {
                id: app.signatory.id,
                document_id: app.document_id,
                surname: app.signatory.surname,
                name: app.signatory.name,
                patronymic: app.signatory.patronymic,
                email: app.signatory.email,
                signatory_phone: app.signatory.phone,
                stamp_place: app.signatory.stamp_place
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    if (result.result.signatory) {
                        if (app.signatory.id) {
                            app.signatories[app.signatory.index].surname = result.result.signatory.surname;
                            app.signatories[app.signatory.index].name = result.result.signatory.name;
                            app.signatories[app.signatory.index].patronymic = result.result.signatory.patronymic;
                            app.signatories[app.signatory.index].phone = result.result.signatory.phone_formatted;
                            app.signatories[app.signatory.index].email = result.result.signatory.email;
                            app.signatories[app.signatory.index].stamp_place = result.result.signatory.stamp_place;
                        } else {
                            result.result.signatory.phone = result.result.signatory.phone_formatted;

                            app.signatories.push(result.result.signatory);
                        }

                        app.hideSignatoryModal();

                        app.$store.dispatch('documents/updateDocumentSignatoriesCount', app.signatories.length);

                        app.$toaster.success('Данные успешно сохранены');
                    }
                } else {
                    if (result.result.message) {
                        app.$toaster.error(result.result.message);
                    } else {
                        app.$toaster.error('Ошибка изменения!');
                    }
                }
            }).catch(error => {
                console.log(error.response)
            });
        },
        editSelectedSignatory(signatory, index)
        {
            const app = this;

            app.signatory.id = signatory.id;
            app.signatory.index = index;
            app.signatory.user_name = signatory.user_name;
            app.signatory.surname = signatory.surname;
            app.signatory.name = signatory.name;
            app.signatory.patronymic = signatory.patronymic;
            app.signatory.email = signatory.email;
            app.signatory.phone = signatory.phone;
            app.signatory.stamp_place = signatory.stamp_place;
            app.signatory.is_stamped = signatory.is_stamped;

            app.$refs['modal-signatory'].show();
        },
        deleteSelectedSignatory(id) {
            const app = this;

            if (!confirm('Вы действительно хотите удалить запись?')) {
                return;
            }

            axios.post('/api/document-signatory/delete-by-ids', {
                ids: [id],
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    app.$toaster.success('Подписант удален');
                } else {
                    if (result.result.message) {
                        app.$toaster.error(result.result.message);
                    } else {
                        app.$toaster.error('Ошибка удаления!');
                    }
                }
            }).catch(error => {
                console.log(error);
                app.$toaster.error('Ошибка удаления');
            });

            let _index = app.signatories.findIndex(signatory => signatory.id == id);

            if (_index != -1) {
                setTimeout(() => {
                    app.signatories.splice(_index, 1);

                    app.$store.dispatch('documents/updateDocumentSignatoriesCount', app.signatories.length);
                }, 100)
            }
        },
        sendLinkSignSms(id) {
            const app = this;

            if (!confirm('Вы действительно хотите отправить смс подписанту?')) {
                return;
            }

            axios.post('/api/document-signatory/send-link-sign-sms', {
                id: id,
                document_id: app.document_id,
                message: 'Подпишите электронный документ по ссылке:',
            }).then(response => {
                const result = response.data.data;

                if (result.code === 'ok' && result.result.success === true) {
                    app.$toaster.success('СМС отправлено!');
                } else {
                    if (result.result.message) {
                        app.$toaster.error(result.result.message);
                    } else {
                        app.$toaster.error('Ошибка отправки!');
                    }
                }
            }).catch(error => {
                console.log(error);
                app.$toaster.error('Ошибка отправки');
            });
        },
        onUpdatePositionUserParentData(data)
        {
            if (data) {
                if (this.signatory.name == null) {
                    this.signatory.name = data.name;
                }

                if (this.signatory.surname == null) {
                    this.signatory.surname = data.surname;
                }

                if (this.signatory.patronymic == null) {
                    this.signatory.patronymic = data.patronymic;
                }

                if (this.signatory.email == null) {
                    this.signatory.email = data.email;
                }

                this.signatory.phone = data.phone_formatted;

                this.positionUser.id = data.id;
                this.positionUser.phone = data.phone_formatted;
            }
        },
        onUpdatePhoneNumber(data) {
            const app = this;

            if (data) {
                app.signatory.phone = data;
            }
        },
        setCompany(event) {
            const app = this;

            let index = app.companyList.findIndex(company => company.id == event.target.value);

            if (index !== -1) {
                if (app.companyList[index].stamp_id) {
                    app.showLeaderStampPlace = true;
                } else {
                    app.showLeaderStampPlace = false;
                }
            }
        },
        setShowDocumentHistory(value) {
            const app = this;

            app.showDocumentHistory = value;
        },
    },
    watch: {
        show (newVal) {
            const app = this;

            if(newVal) {
                axios.post('/api/company/list', {
                    filter_fields: {
                        companyIsVerified: true,
                    },
                }).then((response) => {
                    const result = response.data.data;

                    if (result.code === 'ok' && result.result.success === true) {
                        if (result.result.companies) {
                            app.companyList = result.result.companies;
                        }
                    }
                });

                app.$refs.modalDowloadDocumentForm.show();
            } else {
                app.$refs.modalDowloadDocumentForm.hide();
            }
        },
        getDocumentIdAndDocumentIndex: function (newValue, oldValue) {
            const app = this;

            if (newValue) {
                app.document_id = newValue.document_id;
                app.document_index = newValue.document_index;

                if (newValue.document_id !== null) {
                    if (app.document_id) {
                        axios.post('/api/document/get-document-info', {
                            id: app.document_id,
                        }).then(response => {
                            const result = response.data.data;

                            if (
                                result.code === 'ok'
                                && result.result.success === true
                                && result.result.document
                            ) {
                                app.title = result.result.document.title;
                                app.document_number = result.result.document.document_number;
                                app.executor_title = result.result.document.executor_title;
                                app.executor_who_signed_document = result.result.document.executor_who_signed_document;
                                app.executor_job_title = result.result.document.executor_job_title;
                                app.leader = result.result.document.leader;
                                app.stamp_id = result.result.document.stamp_id;
                                app.stamp_page_number = result.result.document.stamp_page_number;
                                app.document_status = result.result.document.document_status;
                                app.document_phone = result.result.document.document_phone;
                                app.client_id = result.result.document.client_id;
                                app.company_id = result.result.document.company_id;
                                app.leader_stamp_place = result.result.document.leader_stamp_place;
                                app.count_client_signatures = result.result.document.count_client_signatures;
                                app.count_customer_signatures = result.result.document.count_customer_signatures;
                                app.company_role = result.result.document.company_role;
                                app.company_title = result.result.document.company_title;
                                app.signatoried_count = result.result.document.signatoried_count;
                                app.executor_stamp_date_time = result.result.document.executor_stamp_date_time;
                                app.document_generate_type = result.result.document.document_generate_type;

                                if (result.result.document.signatories) {
                                    app.signatories = result.result.document.signatories;
                                }

                                if (result.result.document.document_history) {
                                    app.documentHistory = result.result.document.document_history;
                                }
                            } else {
                                app.$toaster.error('Ошибка получения данных');
                            }
                        }).catch(error => {
                            app.$toaster.error('Ошибка получения данных');
                        });
                    }
                }
            }
        },
    }
}
