import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    typicalContractList: [],
    typicalContractListCount: null,
    typicalContractFilter: {
        main_filter: null,
        fields: {
            typicalContractNumber: null,
            typicalContractTitle: null,
        },
    },
    typicalContractModalOpen: false,
    typicalContractViewOpen: false,
    typical_contract_id: null,
    typical_contract_index: null,
    contractId: null,
    contractDescription: null,
    contractConfirmedAt: null,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
