import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "../store";
import axios from "axios";

Vue.use(VueRouter);

function loggedIn()
{
  return localStorage.getItem('token');
}

const router = new VueRouter({
  mode: 'history',

  routes: [
    {
      path: '/login', component: () => import('../components/login/Login.vue'),
      name: 'login',
      meta: {guest: true, layout: 'GuestLayout'},
    },
    {
      path: '/register', component: () => import('../components/register/Register.vue'),
      name: 'register',
      meta: {guest: true, layout: 'GuestLayout'},
    },
    {
      path: '/account-confirm/:confirm_hash', component: () => import('../components/confirm/Confirm.vue'),
      name: 'confirm',
      meta: {guest: true, layout: 'GuestLayout'},
    },
    {
      path: '/forgot-password', component: () => import('../components/forgot-password/ForgotPassword.vue'),
      name: 'forgot-password',
      meta: {guest: true, layout: 'GuestLayout'},
    },
    {
      path: '/forgot-password/:confirm_hash', component: () => import('../components/forgot-password/ForgotPassword.vue'),
      name: 'forgot-password_hash',
      meta: {guest: true, layout: 'GuestLayout'},
    },
    {
      path: '/privacy', component: () => import('../components/articles/Articles.vue'),
      name: 'privacy',
      meta: {guest: true, layout: 'GuestLayout'},
    },
    {
      path: '/rules', component: () => import('../components/articles/Articles.vue'),
      name: 'rules',
      meta: {guest: true, layout: 'GuestLayout'},
    },
    {
      path: '/statement-rules', component: () => import('../components/articles/Articles.vue'),
      name: 'statement-rules',
      meta: {guest: true, layout: 'GuestLayout'},
    },
    {
      path: '/soglasie_na_obrabotku_personalnyh_dannyh', component: () => import('../components/articles/Articles.vue'),
      name: 'soglasie_na_obrabotku_personalnyh_dannyh',
      meta: {guest: true, layout: 'GuestLayout'},
    },
    /*{
      path: '/', component: () => import('../components/home/Home.vue'),
      name: 'home',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },*/
    /*{
      path: '/', component: () => import('../components/profile-settings/ProfileSettings.vue'),
      name: 'profile-settings',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },*/
    {
      path: '/', component: () => import('../components/profile-settings/ProfileSettings.vue'),
      name: 'home',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/delivery', component: () => import('../components/profile-settings/ProfileSettings.vue'),
      name: 'delivery',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/contacts', component: () => import('../components/profile-settings/ProfileSettings.vue'),
      name: 'contacts',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/payment-methods', component: () => import('../components/profile-settings/ProfileSettings.vue'),
      name: 'payment-methods',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/documents', component: () => import('../components/documents/Documents.vue'),
      name: 'documents',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/clients', component: () => import('../components/clients/Clients.vue'),
      name: 'clients',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/companies', component: () => import('../components/companies/Companies.vue'),
      name: 'companies',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/transactions', component: () => import('../components/transactions/Transactions.vue'),
      name: 'transactions',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/tickets', component: () => import('../components/tickets/Tickets.vue'),
      name: 'tickets',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/stamps', component: () => import('../components/stamps/Stamps.vue'),
      name: 'stamps',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/more-companies', component: () => import('../components/more-companies/MoreCompanies.vue'),
      name: 'more-companies',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/more-documents', component: () => import('../components/more-documents/MoreDocuments.vue'),
      name: 'more-documents',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/typical-contract', component: () => import('../components/typical-contract/TypicalContract.vue'),
      name: 'typical-contract',
      meta: {requiresAuth: true, layout: 'AppLayout'},
      beforeEnter: (to, from, next) => {userHasAccessToApp(to, from, next)},
    },
    {
      path: '/store', component: () => import('../components/store/Store.vue'),
      name: 'store',
      meta: {guest: true, layout: 'GuestLayout'},
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedIn()) {
      next({
        path: '/login',
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (loggedIn()) {
      if (
          to.name == 'privacy'
          || to.name == 'rules'
          || to.name == 'statement-rules'
          || to.name == 'store'
          || to.name == 'soglasie_na_obrabotku_personalnyh_dannyh'
      ) {
        next();
      } else {
        next({
          path: '/',
        });
      }
    } else {
      next();
    }
  }
});

export default router;

function userHasAccessToApp(to, from, next) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

  store.dispatch('currentUser/initUser');

  next();
}
