import LeftNav from '../../components/template/left-nav/LeftNav.vue';
import Avatar from '../../components/template/avatar/Avatar.vue';
import Header from '../../components/template/header/Header.vue'
import InformationModal from "@/components/information-modal/InformationModal.vue";
import Messenger from "@/components/messenger/Messenger.vue";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
    name: "AppLayout",
    components: {
        LeftNav,
        Avatar,
        Header,
        InformationModal,
        Messenger,
    },
    data() {
        return {
            intervalGetChats: null,
        }
    },
    created() {
        const app = this;

        /*app.getChats();

        app.setIntervalGetChats();*/
    },
    computed: {
        ...mapGetters({
            getChatList: 'messanger/getChatList',
        }),
    },
    methods: {
        logoutUser() {
            localStorage.removeItem('token');
            window.location.href = '/';
        },
        /*getChats() {
            const app = this;

            if (!app.getChatList.length) {
                app.$store.dispatch('messanger/setActivePreloader', true);
            }

            axios.post('/api/messenger/get-chats').then(response => {
                const result = response.data.data;

                if (
                    result.code === 'ok'
                    && result.result.success === true
                    && result.result.chats
                ) {
                    if (result.result.chats) {
                        app.$store.dispatch('messanger/setChatList', result.result.chats);
                    }

                    app.$store.dispatch('messanger/setActivePreloader', false);
                } else {
                    app.$toaster.error('Ошибка получения данных');
                }
            }).catch(error => {
                app.$toaster.error('Ошибка получения данных');
            });
        },
        /*setIntervalGetChats() {
            const app = this;

            app.intervalGetChats = setInterval(app.getChats, 60000)
            app.$on('hook:beforeDestroy', () => clearInterval(app.intervalGetChats));
        },*/
    }
}
